import React from 'react';
import { createRoot } from "react-dom/client";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./system/contexts/ThemeContext";
import packageJson from "../package.json"

const container = document.getElementById("root");
const root = createRoot(container);


root.render(
  <BrowserRouter>
  <ThemeProvider>
    <App />
  </ThemeProvider>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let version = localStorage.getItem('version');
if(version!==packageJson.version)
{
    if('caches' in window){
        caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
            caches.delete(name);
        })
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }

    localStorage.setItem('version',packageJson.version);  
}
