import React from "react";
import {
  BrowserRouter as Router,
  Link,
  useRoutes
} from "react-router-dom";
import useTheme from "./system/hooks/useTheme";
import routes from "./system/routes";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { store } from "./system/redux/store";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import createTheme from "./system/theme/index";
import { AuthProvider } from "./system/contexts/JWTContext";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "./utils/createEmotionCache";
import { isMobile } from 'react-device-detect';
import "./i18n";

const clientSideEmotionCache = createEmotionCache();
if(isMobile){
  window.isNativeApp=true;
}else{
  window.isNativeApp=false;
}

const App = ({ emotionCache = clientSideEmotionCache }) => {
  const content = useRoutes(routes);

  const { theme } = useTheme();


  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s"
          defaultTitle="Fatura Digital"
        />
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <AuthProvider>{content}</AuthProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
};

export default App;
