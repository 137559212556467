import React from "react";

import async from "./Async";
import AuthLayout from "./theme/layout/AuthLayout";


// Dashboard components
const Home = async(() => import("../components/Home"));
const PaymentCreditCard = async(() => import("../components/Payment/Payment/index"));
const PaymentCreditCardStatus = async(() => import("../components/Payment/Payment/status"));

const routes = [
    {
        path: "/",
        element: <AuthLayout />,
        children: 
        [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "payment/:invoice_id",
                element: <PaymentCreditCard />,
            },
            {
                path: "payment/:invoice_id/status",
                element: <PaymentCreditCardStatus />,
            }
        ]
    }

 
];

export default routes;
